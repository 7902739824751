

































































































































































































































import Vue, {
  VueConstructor,
} from 'vue'
import {
  newPlace,
} from '@/util/place'
import {
  PropValidator,
} from 'vue/types/options'
import {
  Place,
} from '@/types/place'
import {
  Chapter,
} from '@/types/chapter'
import {
  Id,
} from '@/types/base'
import {
  Icon,
} from '@/types'
import {
  DataTableHeader,
} from 'vuetify'
import {
  chapterStore,
} from '@/store'
import {
  VuetifyTextField,
} from '@/plugins/vuetify/types'
import mixins from 'vue-typed-mixins'
import {
  newChapter,
} from '@/util/chapter'

export default mixins(Vue as VueConstructor<Vue & {
  $refs: {
    chapterIds: VuetifyTextField[]
  }
}>)
  .extend({
    props: {
      place: {
        default: newPlace(),
        type: Object,
      } as PropValidator<Place>,
      loadingGet: {
        default: false,
        type: Boolean,
      },
      loadingAction: {
        default: false,
        type: Boolean,
      },
    },
    data: () => ({
      headers: [
        {
          value: `chapterId`,
          text: `Chapter ID`,
          sortable: false,
        },
      ] as DataTableHeader[],
      place_: newPlace(),
      options: {
        page: 0,
        itemsPerPage: 10,
      },
    }),
    computed: {
      icons (): Icon[] {
        const icons = Object.entries(this.$global.icons)
          .map(([
            value,
            icon
            ,
          ]) => ({
            value,
            ...icon,
          }))
        icons.sort((a, b) => a.translation.localeCompare(b.translation))
        return icons
      },
      chapters (): (Chapter & Id)[] {
        return chapterStore.data
      },
      isLoadingChapterGetAll (): boolean {
        return chapterStore.isLoadingGetAll
      },
    },
    watch: {
      place_: {
        deep: true,
        handler (place: Place) {
          this.$emit(`update:place`, place)
        },
      },
      place: {
        deep: true,
        immediate: true,
        handler (place: Place) {
          this.place_ = place
        },
      },
    },
    methods: {
      getChapterById (id: string): (Chapter & Id) {
        return chapterStore.dataById(id) || {
          id,
          ...newChapter(),
        }
      },
      async addChapterId () {
        this.place_.chapterIds.push(``)
        this.options.page = Math.ceil(this.place_.chapterIds.length / this.options.itemsPerPage)
        await this.$nextTick()
        const chapterIds = this.$refs.chapterIds
        chapterIds[chapterIds.length - 1].focus()
      },
      async moveChapterIdUp (index: number) {
        const {
          chapterIds,
        } = this.place
        const chapterId = chapterIds.splice(index, 1)[0]
        chapterIds.splice(index - 1, 0, chapterId)
      },
      async moveChapterIdDown (index: number) {
        const {
          chapterIds,
        } = this.place
        const chapterId = chapterIds.splice(index, 1)[0]
        chapterIds.splice(index + 1, 0, chapterId)
      },
      loadAllChapters () {
        chapterStore.getAll()
      },
    },
  })
