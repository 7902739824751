var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"autofocus":!_vm.$route.params.id,"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"rules":[
      _vm.$global.rules.required() ],"label":"Name","validate-on-blur":"","outlined":""},model:{value:(_vm.place_.name),callback:function ($$v) {_vm.$set(_vm.place_, "name", $$v)},expression:"place_.name"}}),_c('v-text-field',{attrs:{"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"rules":[
      _vm.$global.rules.required() ],"label":"Translation","validate-on-blur":"","outlined":""},model:{value:(_vm.place_.translation),callback:function ($$v) {_vm.$set(_vm.place_, "translation", $$v)},expression:"place_.translation"}}),_c('v-select',{attrs:{"items":_vm.icons,"item-value":function (icon) { return icon.value; },"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"rules":[
      _vm.$global.rules.required() ],"label":"Icon","validate-on-blur":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('v-layout',{attrs:{"row":"","align-center":"","no-gutters":""}},[_c('v-icon',{staticClass:"mr-3",style:({color: "currentColor"})},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(item.translation))])],1)]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-layout',{attrs:{"row":"","align-center":"","no-gutters":""}},[_c('v-icon',{staticClass:"mr-3",style:({color: "currentColor"})},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(item.translation))])],1)]}}]),model:{value:(_vm.place_.icon),callback:function ($$v) {_vm.$set(_vm.place_, "icon", $$v)},expression:"place_.icon"}}),_c('v-color-picker',{staticClass:"mb-8",attrs:{"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"mode":"hexa","show-swatches":"","hide-mode-switch":""},model:{value:(_vm.place_.color),callback:function ($$v) {_vm.$set(_vm.place_, "color", $$v)},expression:"place_.color"}}),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"rules":[
          _vm.$global.rules.required() ],"label":"X","type":"number","validate-on-blur":"","outlined":""},model:{value:(_vm.place_.x),callback:function ($$v) {_vm.$set(_vm.place_, "x", _vm._n($$v))},expression:"place_.x"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"loading":_vm.loadingGet,"disabled":_vm.loadingAction,"rules":[
          _vm.$global.rules.required() ],"label":"Y","type":"number","validate-on-blur":"","outlined":""},model:{value:(_vm.place_.y),callback:function ($$v) {_vm.$set(_vm.place_, "y", _vm._n($$v))},expression:"place_.y"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.place_.chapterIds,"headers":_vm.headers,"options":_vm.options},scopedSlots:_vm._u([{key:"body",fn:function(ref){
        var items = ref.items;
return [_c('tbody',[_vm._l((items),function(_,i){return _c('tr',{key:("chapterId-" + i)},[_c('td',[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.place_.chapterIds && _vm.place_.chapterIds.length > 1)?_c('v-row',{staticClass:"flex-column flex-grow-0",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"disabled":i === 0,"text":"","color":"grey darken-1","width":"19","height":"19","min-width":"0"},on:{"click":function($event){return _vm.moveChapterIdUp(i)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"disabled":i === _vm.place_.chapterIds.length - 1,"text":"","color":"grey darken-1","width":"19","height":"19","min-width":"0"},on:{"click":function($event){return _vm.moveChapterIdDown(i)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1):_vm._e(),_c('v-combobox',{ref:"chapterIds",refInFor:true,attrs:{"loading":_vm.isLoadingChapterGetAll,"items":_vm.chapters,"item-value":function (item) { return item.id; },"return-object":false,"rules":[
                      _vm.$global.rules.required() ],"label":"Chapter ID","single-line":"","dense":"","flat":"","solo":"","hide-details":"","background-color":"grey lighten-4"},on:{"focus":_vm.loadAllChapters},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var id = ref.item;
return [_c('div',[_c('span',{},[_vm._v(_vm._s(_vm.getChapterById(id).name)+" - ")]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.getChapterById(id).translation))]),_c('span',{staticClass:"grey--text overline"},[_vm._v(" ("+_vm._s(id)+")")])])]}},{key:"item",fn:function(ref){
                    var chapter = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(chapter.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(chapter.translation)+" ")])],1),_c('v-list-item-action',[_c('v-list-item-title',{staticClass:"overline"},[_vm._v(" "+_vm._s(chapter.id)+" ")])],1)]}}],null,true),model:{value:(_vm.place_.chapterIds[i]),callback:function ($$v) {_vm.$set(_vm.place_.chapterIds, i, $$v)},expression:"place_.chapterIds[i]"}}),(_vm.place_.chapterIds.length > 1)?_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){return _vm.place_.chapterIds.splice(i, 1)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)])}),_c('tr',[_c('td',[_c('v-btn',{attrs:{"text":"","block":"","color":"accent"},on:{"click":function($event){return _vm.addChapterId()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add more ")],1)],1)])],2)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }